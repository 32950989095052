import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../logo.svg";
import user from "../Image/user.png";
import useVerify from "../hooks/useVerify";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import axios from "axios";
import { GET_MESSAGE, GET_SYMBOL, MAIN, USER_INFO } from "../utils/Api";
import { useSymbol } from "../provider/ContextProvider";
import { getUploadTime } from "../utils/GetCount";
import { Bars } from "react-loader-spinner";
import { TypeAnimation } from "react-type-animation";

const Sidebar = () => {
  const [UserDetail, setUserDetail] = useState("");
  const [Messages, setMessages] = useState([]);
  const [isLoad, setisLoad] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["users"]);
  const { Symbol, setSymbol } = useSymbol();
  const location = useLocation();
  const navigate = useNavigate();
  useVerify();
  function handleLogout() {
    removeCookie("users");
    toast.success("LogOut Successfully !");
    navigate("/");
  }
  async function getUser() {
    try {
      let obj = {
        user_id: cookies?.users,
      };
      const { data } = await axios.post(USER_INFO, obj);
      if (data?.user?.currency) {
        let obj = {
          currency_name: data?.user?.currency,
        };
        const { data: CurrData } = await axios.post(GET_SYMBOL, obj);
        setSymbol(CurrData?.data?.symbol);
      }
      setUserDetail(data?.user);
      const { data: Message } = await axios.post(GET_MESSAGE);
      setMessages(Message?.data);
    } catch (er) {
      console.log(er);
    } finally {
      setisLoad(true);
    }
  }
  useEffect(() => {
    getUser();
  }, []);
  return (
    <div>
      {/* nav fixx */}
      <div className="sidebar">
        <div className="sidebar-logo">
          <Link to={"/Dashboard"}>
            <img
              src={logo}
              onError={(event) => {
                event.currentTarget.src = user;
                event.currentTarget.className = "error";
              }}
            />
          </Link>
        </div>
        <div className="overflow-y">
          <div className="sidebar-link">
            <ul>
              <li
                className={location.pathname === "/Dashboard" ? "active" : ""}
              >
                <NavLink to={"/Dashboard"}>
                  <i className="fa-solid fa-house"></i>Dashboard
                </NavLink>
              </li>
              <li className={location.pathname === "/About" ? "active" : ""}>
                <NavLink to={"/About"}>
                  <i className="fa-solid fa-user"></i>Account Settings
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="page-line">
            <hr />
            <span>Pages</span>
          </div>
          <div className="sidebar-link">
            <ul>
              <li
                className={location.pathname === "/Technology" ? "active" : ""}
              >
                <NavLink to={"/Technology"}>
                  <i className="fa-solid fa-code"></i>Technology
                </NavLink>
              </li>
              <li className={location.pathname === "/Module" ? "active" : ""}>
                <NavLink to={"/Module"}>
                  <i className="fa-solid fa-puzzle-piece"></i>Module
                </NavLink>
              </li>
              <li className={location.pathname === "/Project" ? "active" : ""}>
                <NavLink to={"/Project"}>
                  <i className="fa-solid fa-rocket"></i>Project
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="page-line">
            <hr />
            <span>useful links</span>
          </div>
          <div className="sidebar-link">
            <ul>
              <li className={location.pathname === "/Contact" ? "active" : ""}>
                <NavLink to={"/Contact"}>
                  <i className="fa-regular fa-address-book"></i>Contact Us
                </NavLink>
              </li>
              <li className={location.pathname === "/" ? "active" : ""}>
                <NavLink to={"/Terms"}>
                  <i className="fa-regular fa-credit-card"></i>Terms and
                  Conditions
                </NavLink>
              </li>
              <li className={location.pathname === "/" ? "active" : ""}>
                <a
                  target="_blank"
                  href="https://www.quickmake.4born.in/privacy-policy.html"
                >
                  <i className="fa-regular fa-eye"></i>Privacy Policy
                </a>
              </li>
              <li className={location.pathname === "/" ? "active" : ""}>
                <NavLink to={"/Refund"}>
                  <i className="fa-regular fa-credit-card"></i>Refund Policy
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="pro-btn">
          <Link to={"/Membership"} type="submit" className="btn btn-pro">
            Upgrade to Pro
          </Link>
        </div> */}
      </div>
      <div className="fixx">
        <nav className="top-header navbar navbar-expand-lg sticky-top navbar-light">
          <div className="py-2 container-fluid ">
            <div className="mt-3">
              {isLoad &&
                (UserDetail?.organizations &&
                UserDetail?.email &&
                UserDetail?.state ? (
                  <p>
                    <TypeAnimation
                      sequence={[
                        // Same substring at the start will only be typed out once, initially
                        `Hi,${UserDetail?.username}`,
                        3000, // wait 1s before replacing "Mice" with "Hamsters"
                        `${UserDetail?.organizations}`,
                        3000,
                      ]}
                      wrapper="span"
                      speed={20}
                      style={{ fontSize: "1.2rem", display: "inline-block" }}
                      repeat={Infinity}
                    />
                  </p>
                ) : (
                  <p className="shake-bottom text-danger">
                    Please Full Fill User Detail !
                  </p>
                ))}
              {/* <span className="fa fa-search form-control-feedback"></span>
              <input
                type="text"
                className="form-control border-0"
                placeholder="Search"
              /> */}
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse navheight justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav navbarbottom mb-lg-0 align-items-lg-center">
                <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
                  <Link
                    className="nav-link hide-arrow"
                    href="javascript:void(0);"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                  >
                    <NavLink
                      className="nav-link bell"
                      aria-current="page"
                      to={"#"}
                    >
                      <i className="fa-regular fa-bell"></i>
                    </NavLink>
                  </Link>
                  <ul
                    className="dropdown-menu dropdown-menu-end py-0"
                    data-bs-popper="static"
                  >
                    <li className="dropdown-menu-header border-bottom">
                      <div className="dropdown-header d-flex align-items-center py-3">
                        <h5 className="text-body mb-0 me-auto">Notification</h5>
                        <Link
                          to={"#"}
                          className="dropdown-notifications-all text-body"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          aria-label="Mark all as read"
                          data-bs-original-title="Mark all as read"
                        >
                          <i class="fa-regular fa-envelope-open"></i>
                        </Link>
                      </div>
                    </li>
                    <li className="dropdown-notifications-list scrollable-container ps ps--active-y">
                      <ul className="list-group list-group-flush all-project-table">
                        {isLoad ? (
                          !Messages?.length ? (
                            <li className="d-flex justify-content-center  align-items-center h-100">
                              There not messages !
                            </li>
                          ) : (
                            Messages?.map((item) => {
                              return (
                                <li
                                  className="list-group-item list-group-item-action dropdown-notifications-item"
                                  key={item * 46}
                                >
                                  <div className="d-flex">
                                    <div className="flex-shrink-0 me-3">
                                      <div className="avatar">
                                        <img
                                          onError={(event) => {
                                            event.currentTarget.src = user;
                                            event.currentTarget.className =
                                              "error";
                                          }}
                                          src={`${MAIN}/${item?.image}`}
                                          className="w-px-40 h-100  rounded-circle"
                                        />
                                      </div>
                                    </div>
                                    <div className="flex-grow-1">
                                      <h6 className="mb-1">{item?.title}</h6>
                                      <p className="mb-0">{item?.message}</p>
                                      <small className="text-muted">
                                        {getUploadTime(item?.Create_at)}
                                      </small>
                                    </div>
                                    <div className="flex-shrink-0 dropdown-notifications-actions">
                                      <Link
                                        to={"#"}
                                        className="dropdown-notifications-read"
                                      >
                                        <span className="badge badge-dot"></span>
                                      </Link>
                                      <Link
                                        to={"#"}
                                        className="dropdown-notifications-archive"
                                      >
                                        <span className="bx bx-x"></span>
                                      </Link>
                                    </div>
                                  </div>
                                </li>
                              );
                            })
                          )
                        ) : (
                          <div className="d-flex h-100 justify-content-center  align-items-center ">
                            <Bars
                              height="50"
                              width="50"
                              color="#C256FA"
                              ariaLabel="bars-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                            />
                          </div>
                        )}
                      </ul>
                    </li>
                  </ul>
                </li>
                <li className="nav-item  dropdown me-3 me-xl-1">
                  <Link
                    type="button"
                    // className="navbar-user position-relative border-0 bg-transparent nav-link hide-arrow"
                    className="dropdown-toggle navbar-user position-relative border-0 bg-transparent hide-arrow"
                    data-bs-toggle="dropdown"
                  >
                    <img
                      src={isLoad ? UserDetail?.ProfileImageURL : user}
                      onError={(event) => {
                        event.currentTarget.src = user;
                        event.currentTarget.className = "error";
                      }}
                    />
                    {/* <span className="position-absolute bottom-0 right-25 start-100 translate-middle p-1 bg-light-green border border-light rounded-circle">
                      <span className="visually-hidden">New alerts</span>
                    </span> */}
                  </Link>
                  <div className="dropdown-menu dropdown-menu-end ">
                    <Link
                      to={"/About"}
                      className="dropdown-item d-flex align-items-center justify-content-between  avtart-link  gap-2"
                    >
                      <div>Account </div>
                      <i className="fa-solid fa-user"></i>
                    </Link>
                    <div
                      onClick={handleLogout}
                      className="dropdown-item d-flex align-items-center justify-content-between avtart-link    gap-2"
                    >
                      <div>Logout </div>
                      <i className="fa fa-sign-in" aria-hidden="true"></i>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
